// third-party
import { FormattedMessage } from 'react-intl';

import { NavItemType } from 'types';

// assets
import {
    IconApps,
    IconTicket,
    IconUsers,
    IconReceipt,
    IconTags,
    IconCalculator,
    IconCreditCard,
    IconFileInvoice,
    IconUserCircle,
    IconPackage,
    IconListSearch,
    IconChartInfographic,
    IconWallet
} from '@tabler/icons';

// ==============================|| APPLICATION MENU ITEMS ||============================== //

const payloot: NavItemType = {
    id: 'benefits',
    // title: <FormattedMessage id="benefits" />,
    icon: IconApps,
    type: 'group',
    children: [
        {
            id: 'coupons',
            title: <FormattedMessage id="coupons" />,
            type: 'item',
            icon: IconTicket,
            url: '/coupons'
        },
        {
            id: 'bonuses',
            title: <FormattedMessage id="bonuses" />,
            type: 'item',
            icon: IconWallet,
            url: '/bonuses'
        },
        {
            id: 'clients',
            title: <FormattedMessage id="clients" />,
            type: 'item',
            icon: IconUsers,
            url: '/clients',
            disabled: false
        },
        {
            id: 'deals',
            title: <FormattedMessage id="deals" />,
            type: 'item',
            icon: IconTags,
            url: '/deals'
        },
        {
            id: 'orders',
            title: <FormattedMessage id="orders" />,
            type: 'item',
            icon: IconReceipt,
            url: '/orders',
            disabled: true
        },
        {
            id: 'items',
            title: <FormattedMessage id="items" />,
            type: 'item',
            url: '/items',
            icon: IconPackage,
            breadcrumbs: true
        },
        {
            id: 'simulator',
            title: <FormattedMessage id="simulator" />,
            type: 'item',
            icon: IconCalculator,
            url: '/simulator',
            disabled: true
        },
        {
            id: 'cards',
            title: <FormattedMessage id="cards" />,
            type: 'item',
            icon: IconCreditCard,
            url: '/loyalty/cards',
            disabled: true
        },
        {
            id: 'transactions',
            title: <FormattedMessage id="transactions" />,
            type: 'item',
            icon: IconFileInvoice,
            url: '/loyalty/transactions',
            disabled: true
        },
        {
            id: 'users',
            title: <FormattedMessage id="users" />,
            type: 'item',
            url: '/users',
            icon: IconUserCircle,
            breadcrumbs: true
        },
        {
            id: 'logs',
            title: <FormattedMessage id="logs" />,
            type: 'item',
            url: '/logs',
            icon: IconListSearch,
            breadcrumbs: true
        },
        {
            id: 'reports',
            title: <FormattedMessage id="reports" />,
            type: 'item',
            url: '/reports',
            icon: IconChartInfographic,
            breadcrumbs: true,
            disabled: false
        }
    ]
};

export default payloot;
