import { memo } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Typography, useMediaQuery } from '@mui/material';

// project imports
import menuItem from 'menu-items';
import NavGroup from './NavGroup';
import { NavItemType } from 'types';
import LAYOUT_CONST from 'constant';
import { HORIZONTAL_MAX_ITEM } from 'config';
import useConfig from 'hooks/useConfig';
import useAuth from 'hooks/useAuth';

// ==============================|| SIDEBAR MENU LIST ||============================== //

type ItemType = {
    id: string;
    type: 'group';
    children: NavItemType[];
    title: string;
};

type ObjectType = {
    items: ItemType[];
};

const MenuList = () => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

    const { workspace } = useAuth();
    const { layout } = useConfig();

    function workspaceURL(obj: ObjectType): ObjectType {
        return {
            ...obj,
            items: obj.items.map((item, index) => {
                if (index === 1 && item.children) {
                    return {
                        ...item,
                        children: item.children.map((child) => {
                            return {
                                ...child,
                                url: `/${workspace}${child.url}`
                            };
                        })
                    };
                }
                return item;
            })
        };
    }

    const newMenuItems = workspaceURL(menuItem as ObjectType);

    // last menu-item to show in horizontal menu bar
    const lastItem = layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd ? HORIZONTAL_MAX_ITEM : null;

    let lastItemIndex = newMenuItems.items.length - 1;
    let remItems: NavItemType[] = [];
    let lastItemId: string;

    if (lastItem && lastItem < newMenuItems.items.length) {
        lastItemId = newMenuItems.items[lastItem - 1].id!;
        lastItemIndex = lastItem - 1;
        remItems = newMenuItems.items.slice(lastItem - 1, newMenuItems.items.length).map((item) => ({
            title: item.title,
            elements: item.children
        }));
    }

    const navItems = newMenuItems.items.slice(0, lastItemIndex + 1).map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} lastItem={lastItem!} remItems={remItems} lastItemId={lastItemId} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default memo(MenuList);
