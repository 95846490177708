// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../../index';
import * as R from 'ramda';

// types
import { UserType, Users, CheckType } from 'types';
import { openSnackbar } from '../snackbar';
import { API_URL } from 'config';

// ----------------------------------------------------------------------

const initialState: Users = {
    users: [],
    nextToken: '',
    check: {} as CheckType,
    loading: false,
    workspaces: []
};

const slice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        // HAS ERROR
        // hasError(state, action) {
        //     state.error = action.payload;
        // },

        // GET USERS STYLE 1
        setLoading(state, action) {
            state.loading = action.payload;
        },
        getUsersSuccess(state, action) {
            const search = action?.payload?.config?.params?.search;
            const users = R.uniqBy((i: UserType) => i.id, (search && action.payload.data) || R.concat(action.payload.data, state.users));
            state.users = users;
            const nextToken = action?.payload?.headers?.['x-next-token'];
            state.nextToken = nextToken;
        },
        deleteUserSuccess(state, action) {
            state.users = state.users.filter((user) => user.id !== action.payload);
        },
        getUserStatusChecker(state, action) {
            state.check = action.payload.data;
        },
        getWorkspacesSuccess(state, action) {
            state.workspaces = action.payload.data;
        },
        clearUsers(state, action) {
            state.users = [];
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getUsersList(search?: string, nextToken?: string) {
    return async () => {
        try {
            dispatch(slice.actions.setLoading(true));
            const response = await axios.get('/users', {
                params: {
                    ...(search && { search })
                },
                headers: {
                    ...(nextToken && { 'x-next-token': nextToken }),
                    'Cache-Control': 'no-cache',
                    Connection: 'keep-alive'
                }
            });
            dispatch(slice.actions.setLoading(false));
            dispatch(slice.actions.getUsersSuccess(response));
            dispatch(slice.actions.setLoading(false));
        } catch (error) {
            displayError(error);
        }
    };
}

export function registrationUser(email: string, nextToken?: string) {
    return async () => {
        try {
            dispatch(slice.actions.setLoading(true));
            const response = await axios.get(`/users/${email}/access`, {
                headers: {
                    ...(nextToken && { 'x-next-token': nextToken }),
                    'Cache-Control': 'no-cache',
                    Connection: 'keep-alive'
                }
            });

            dispatch(slice.actions.getUserStatusChecker(response));
            dispatch(slice.actions.setLoading(false));
        } catch (error) {
            displayError(error);
        }
    };
}
export function userWorkspace(email: string) {
    return async () => {
        try {
            await axios.patch(`/users/${email}/access`);

            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Workspace Successfully Associated',
                    variant: 'alert',
                    close: true,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left'
                    },
                    alert: {
                        variant: 'filled'
                    }
                })
            );
        } catch (error) {
            displayError(error);
        }
    };
}

export function getWorkspaces() {
    return async () => {
        try {
            dispatch(slice.actions.setLoading(true));
            const response = await axios.get(`${API_URL}/api/workspaces`);
            dispatch(slice.actions.getWorkspacesSuccess(response));
            dispatch(slice.actions.setLoading(false));
        } catch (error) {
            displayError(error);
        }
    };
}

export function removeUser(id?: string) {
    return async () => {
        try {
            await axios.delete(`/users/${id}`);

            dispatch(slice.actions.deleteUserSuccess(id));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Client successfully deleted',
                    variant: 'alert',
                    close: true,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left'
                    },
                    alert: {
                        variant: 'filled'
                    }
                })
            );
        } catch (error) {
            displayError(error);
        }
    };
}

const displayError = async (error: any) => {
    dispatch(
        openSnackbar({
            open: true,
            message: `${
                error?.message ||
                error?.response?.data?.issues ||
                error?.response?.data?.message ||
                (error?.response?.data && JSON.stringify(error?.response?.data)) ||
                error
            }`,
            variant: 'alert',
            close: true,
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
            },
            alert: {
                color: 'error',
                variant: 'filled'
            }
        })
    );
};

export function ClearUsers() {
    return async () => {
        try {
            dispatch(slice.actions.clearUsers([]));
        } catch (error) {}
    };
}
