// material-ui
import { useEffect, useMemo, useState } from 'react';
import { Grid, Avatar, AppBar, Toolbar, useTheme, CircularProgress } from '@mui/material';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import CommitIcon from '@mui/icons-material/Commit';
import TodayIcon from '@mui/icons-material/Today';
import HeaderDocs from '../docs/HeaderDocs';
import './index.css';
import ReactMarkdown from 'react-markdown';
import axios from 'utils/axios';

const ReleasesView = () => {
    const [releases, setReleases] = useState([]);
    const [loading, setLoading] = useState(false);
    const theme = useTheme();
    const header = useMemo(
        () => (
            <Toolbar>
                <HeaderDocs />
            </Toolbar>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );
    const getInformationRelease = async () => {
        try {
            setLoading(true);
            const response = await axios.get('/releases');
            setReleases(response.data);
            setLoading(false);
        } catch (err) {
            console.error(err);
            setLoading(false);
        }
    };

    useEffect(() => {
        getInformationRelease();
    }, []);

    return (
        <>
            <AppBar enableColorOnDark position="sticky" color="inherit" sx={{ bgcolor: theme.palette.background.default }}>
                {header}
            </AppBar>
            {!loading ? (
                <Grid>
                    {releases?.map((release: any) => (
                        <Grid
                            container
                            className="general-container"
                            sx={{
                                padding: { xs: '0px 20px', sm: '0px 20px', md: '0px 100px', lg: '0px 150px', xl: '0px 150px' }
                            }}
                        >
                            <Grid item xs={12} sm={12} md={12} lg={2} xl={2} className="general-author-item">
                                <Grid container sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Grid item xs={12} sm={3} md={3} lg={12} xl={12} className="author-item">
                                        <TodayIcon fontSize="small" />
                                        <p className="author-text">{release?.published_at.slice(0, 10)}</p>
                                    </Grid>
                                    <Grid item xs={12} sm={3} md={3} lg={12} xl={12} className="author-item">
                                        <Avatar alt="avatar" src={release?.author?.avatar_url} className="author-avatar" />
                                        <p className="author-text">{release?.author?.login}</p>
                                    </Grid>
                                    <Grid item xs={12} sm={3} md={3} lg={12} xl={12} className="author-item">
                                        <LocalOfferIcon fontSize="small" />
                                        <p className="author-text">{release?.tag_name}</p>
                                    </Grid>
                                    <Grid item xs={12} sm={3} md={3} lg={12} xl={12} className="author-item">
                                        <CommitIcon fontSize="small" />
                                        <p className="author-text">{release?.target_commitish}</p>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* eslint-disable-next-line */}
                            <Grid item xs={12} sm={12} md={12} lg={10} xl={10} className="release-item">
                                <h1>{release?.name}</h1>
                                <ReactMarkdown children={release?.body} />
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <Grid className="loading-spinner">
                    <CircularProgress />
                </Grid>
            )}
        </>
    );
};

export default ReleasesView;
