// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Button, Typography } from '@mui/material';

import { Link } from 'react-router-dom';

// project imports
import LogoSection from 'layout/MainLayout/LogoSection';
import { IconArrowUpRight } from '@tabler/icons';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
    const theme = useTheme();

    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    <LogoSection />
                </Box>
            </Box>

            {/* header search */}
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} />
            <Button
                size="large"
                component={Link}
                to="/coupons"
                variant="outlined"
                sx={{ display: 'flex', gap: 1, alignItems: 'center', borderRadius: 3 }}
            >
                <IconArrowUpRight />
                <Typography>Visit Site</Typography>
            </Button>
        </>
    );
};

export default Header;
