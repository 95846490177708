/**
 * axios setup to use mock service
 */

import axios from 'axios';
import { API_URL } from 'config';

const axiosServices = axios.create({
    headers: {
        Authorization: `Bearer ${localStorage.getItem('serviceToken')}`
    }
});

axiosServices.interceptors.request.use(async (config) => {
    let workspace = localStorage.getItem('workspace');

    if (workspace === null) {
        // Reintentar la solicitud después de un breve retraso
        await new Promise((resolve) => setTimeout(resolve, 500));
        workspace = localStorage.getItem('workspace');
    }

    if (workspace) {
        config.baseURL = `${API_URL}/api/${workspace}`;
    }

    return config;
});

//set service token
axiosServices.interceptors.request.use((config) => {
    const token = localStorage.getItem('serviceToken');

    if (token && config && config.headers) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
});

// interceptor for http
axiosServices.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => Promise.reject(error)
);

export const axiosMocker = axios.create();

// interceptor for http
axiosMocker.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => Promise.reject((error.response && error.response.data) || 'Wrong Services')
);

export default axiosServices;
