// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../../index';

// types
import { DefaultRootStateProps } from 'types';
import { Coupon } from 'types/payloot/coupon';
import { openSnackbar } from '../snackbar';
import * as R from 'ramda';

// ----------------------------------------------------------------------

const initialState: DefaultRootStateProps['coupon'] = {
    error: null,
    loading: false,
    nextToken: null,
    coupons: [],
    couponsCount: { value: 0, count: 0, active: false, name: '', id: '' },
    countLoading: false
};

const slice = createSlice({
    name: 'coupon',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },
        setLoading(state, action) {
            state.loading = action.payload;
        },
        setCountLoading(state, action) {
            state.countLoading = action.payload;
        },
        getCouponsSuccess(state, action) {
            console.log(action.payload);
            const search = action?.payload?.config?.params?.search;

            if (action?.payload?.data?.length < 1) {
                state.coupons = [];
                return;
            }
            const coupons = R.uniqBy((i: Coupon) => i.id, (search && action.payload.data) || R.concat(action.payload.data, state.coupons));
            const nextToken = action?.payload?.headers?.['x-next-token'];

            state.coupons = coupons?.sort((a, b) => (b.endDate > a.endDate ? 1 : -1));
            state.nextToken = nextToken;
        },
        deleteCouponSuccess(state, action) {
            state.coupons = state.coupons?.filter((coupon) => coupon.code !== action.payload);
        },
        getCouponCountSuccess(state, action) {
            state.couponsCount = action.payload.data;
        },
        clearCoupons(state, action) {
            state.coupons = [];
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getCoupons(search?: string, nextToken?: string) {
    return async () => {
        try {
            dispatch(slice.actions.setLoading(true));
            const response = await axios.get('/coupons', {
                params: {
                    ...(search && { search })
                },
                headers: {
                    ...(nextToken && { 'x-next-token': nextToken }),
                    'Cache-Control': 'no-cache',
                    Connection: 'keep-alive'
                }
            });

            dispatch(slice.actions.getCouponsSuccess(response));
            dispatch(slice.actions.setLoading(false));
        } catch (error) {
            console.log(error);
            dispatch(slice.actions.hasError(error));
            displayError(error);
        }
    };
}

export function updateCoupon(coupon: Coupon) {
    return async () => {
        try {
            await axios.patch(`/coupons/${coupon.code}`, coupon);
            displaySuccess('Coupon updated successfully');
        } catch (error: any) {
            if (error.response.status === 423) {
                displayError('Coupon locked');
            } else {
                dispatch(slice.actions.hasError(error));
                displayError(error);
            }
        }
    };
}

export function deleteCoupon(coupon: Coupon) {
    return async () => {
        try {
            await axios.delete(`/coupons/${coupon.code}`);
            dispatch(slice.actions.deleteCouponSuccess(coupon.code));
            displaySuccess('Coupon deleted successfully');
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            displayError(error);
        }
    };
}

export function createCoupon(coupon: Coupon) {
    return async () => {
        try {
            await axios.post(`/coupons`, coupon);
            displaySuccess('Coupon created successfully');
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            await displayError(error);
        }
    };
}

export function getCouponsCount(coupon: Coupon) {
    return async () => {
        try {
            dispatch(slice.actions.setCountLoading(true));
            const response = await axios.get(`/coupons/${coupon.code}/count`);
            dispatch(slice.actions.getCouponCountSuccess(response));
            dispatch(slice.actions.setCountLoading(false));
        } catch (error: any) {
            if (error.response.status === 404) {
                dispatch(
                    slice.actions.getCouponCountSuccess({
                        value: 0,
                        count: 0,
                        active: true,
                        name: '',
                        id: ''
                    })
                );
            } else {
                await displayError(error);
            }
        }
    };
}

const displayError = async (error: any) => {
    console.log(error);
    dispatch(
        openSnackbar({
            open: true,
            message: `${
                error?.message ||
                error?.response?.data?.issues ||
                error?.response?.data?.message ||
                (error?.response?.data && JSON.stringify(error?.response?.data)) ||
                error
            }`,
            variant: 'alert',
            close: true,
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
            },
            alert: {
                color: 'error',
                variant: 'filled'
            }
        })
    );
};

const displaySuccess = async (message?: string) => {
    dispatch(getCoupons());
    dispatch(slice.actions.setLoading(false));
    dispatch(
        openSnackbar({
            open: true,
            message: `${message || 'Success'}`,
            variant: 'alert',
            close: true,
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
            },
            alert: {
                variant: 'filled'
            }
        })
    );
};

export function ClearCoupons() {
    return async () => {
        try {
            dispatch(slice.actions.clearCoupons([]));
        } catch (error) {}
    };
}
