import dashboard from './dashboard';
// import widget from './widget';
// import application from './application';
// import forms from './forms';
// import elements from './elements';
// import pages from './pages';
// import utilities from './utilities';
// import support from './support';
// import other from './other';
import { NavItemType } from 'types';
import payloot from './payloot';

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
    items: [
        // ...((process.env.REACT_APP_ENVIRONMENT === 'development' && [
        //     dashboard,
        //     widget,
        //     application,
        //     forms,
        //     elements,
        //     pages,
        //     utilities,
        //     support,
        //     other,
        //     payloot
        // ]) ||
        //     []),
        dashboard,
        payloot
    ]
};

export default menuItems;
