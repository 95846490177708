// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../../index';

// types
import { DefaultRootStateProps } from 'types';
import { openSnackbar } from '../snackbar';
import * as R from 'ramda';
import { LogsType, lastFilter } from 'types/e-commerce';
import { filterBy, getLogsI } from 'types/payloot/log';

// ----------------------------------------------------------------------

const initialState: DefaultRootStateProps['logs'] = {
    error: null,
    loading: false,
    nextToken: null,
    logs: [],
    lastFilter: {} as lastFilter
};

const slice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },
        setLoading(state, action) {
            state.loading = action.payload;
        },
        getLogsSuccess(state, action) {
            const search = action?.payload?.response?.config?.params?.search;
            const currentFilter = action?.payload?.filter;
            const logs =
                JSON.stringify(state.lastFilter) === JSON.stringify(currentFilter)
                    ? R.uniqBy(
                          (i: LogsType) => i.id,
                          (search && action.payload.response.data) || R.concat(action.payload.response.data, state.logs)
                      )
                    : action.payload.response.data;

            const nextToken = action?.payload?.response.headers?.['x-next-token'];

            state.logs = logs.length
                ? logs?.sort((a: LogsType, b: LogsType) => {
                      let dateA = new Date(a.date);
                      let dateB = new Date(b.date);

                      if (isNaN(dateA.getTime())) {
                          return 1;
                      }
                      if (isNaN(dateB.getTime())) {
                          return -1;
                      }
                      return dateB.getTime() - dateA.getTime();
                  })
                : logs;

            state.nextToken = nextToken;
            state.lastFilter = currentFilter;
        },
        clearLogs(state, action) {
            state.logs = [];
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getLogs({ filter, nextToken, name, id, date }: getLogsI) {
    return async () => {
        try {
            dispatch(slice.actions.setLoading(true));

            const response = await axios.get(`/logs/${filter}`, {
                headers: {
                    ...(nextToken && { 'x-next-token': nextToken }),
                    'Cache-Control': 'no-cache',
                    Connection: 'keep-alive'
                },
                params: { filter, nextToken, name, id, date }
            });

            dispatch(slice.actions.getLogsSuccess({ response, filter: { filter, name, id, date } }));
            dispatch(slice.actions.setLoading(false));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            displayError(error);
        }
    };
}

const displayError = async (error: any) => {
    dispatch(
        openSnackbar({
            open: true,
            message: `${
                error?.message ||
                error?.response?.data?.issues ||
                error?.response?.data?.message ||
                (error?.response?.data && JSON.stringify(error?.response?.data)) ||
                error
            }`,
            variant: 'alert',
            close: true,
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
            },
            alert: {
                color: 'error',
                variant: 'filled'
            }
        })
    );
};

export function ClearLogs() {
    return async () => {
        try {
            dispatch(slice.actions.clearLogs([]));
        } catch (error) {}
    };
}
