// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../../index';

// types
import { DefaultRootStateProps } from 'types';
import { openSnackbar } from '../snackbar';
import * as R from 'ramda';
import { ProductType } from 'types/e-commerce';

// ----------------------------------------------------------------------

const initialState: DefaultRootStateProps['products'] = {
    error: null,
    loading: false,
    nextToken: null,
    products: []
};

const slice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },
        setLoading(state, action) {
            state.loading = action.payload;
        },
        getProductSuccess(state, action) {
            const search = action?.payload?.config?.params?.search;
            const products = R.uniqBy(
                (i: ProductType) => i.id,
                (search && action.payload.data) || R.concat(action.payload.data, state.products)
            );
            const nextToken = action?.payload?.headers?.['x-next-token'];

            state.products = products?.sort((a, b) => {
                if (a.name === undefined || a.name === null) {
                    return 1; // Mandar 'a' al final si 'name' es undefined o null
                }
                if (b.name === undefined || b.name === null) {
                    return -1; // Mandar 'b' al final si 'name' es undefined o null
                }
                return a.name.localeCompare(b.name);
            });

            state.nextToken = nextToken;
        },
        deleteProductSuccess(state, action) {
            state.products = state.products.filter((product) => product.id !== action.payload);
        },
        clearProducts(state, action) {
            state.products = [];
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getProducts(search?: string, nextToken?: string) {
    return async () => {
        try {
            dispatch(slice.actions.setLoading(true));

            const response = await axios.get('/items', {
                params: {
                    ...(search && { search })
                },
                headers: {
                    ...(nextToken && { 'x-next-token': nextToken }),
                    'Cache-Control': 'no-cache',
                    Connection: 'keep-alive'
                }
            });

            dispatch(slice.actions.getProductSuccess(response));
            dispatch(slice.actions.setLoading(false));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            displayError(error);
        }
    };
}

export function updateProduct(product: ProductType) {
    return async () => {
        try {
            await axios.patch(`/items/${product.id}`, product);
            displaySuccess('Item updated successfully');
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            displayError(error);
        }
    };
}

export function deleteProduct(product: ProductType) {
    return async () => {
        try {
            await axios.delete(`/items/${product.id}`);
            dispatch(slice.actions.deleteProductSuccess(product.id));
            displaySuccess('Item deleted successfully');
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            displayError(error);
        }
    };
}

export function createProduct(product: ProductType) {
    return async () => {
        try {
            await axios.post(`/items`, product);
            displaySuccess('Item created successfully');
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            await displayError(error);
        }
    };
}

const displayError = async (error: any) => {
    dispatch(
        openSnackbar({
            open: true,
            message: `${
                error?.message ||
                error?.response?.data?.issues ||
                error?.response?.data?.message ||
                (error?.response?.data && JSON.stringify(error?.response?.data)) ||
                error
            }`,
            variant: 'alert',
            close: true,
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
            },
            alert: {
                color: 'error',
                variant: 'filled'
            }
        })
    );
};

const displaySuccess = async (message?: string) => {
    dispatch(getProducts());
    dispatch(slice.actions.setLoading(false));
    dispatch(
        openSnackbar({
            open: true,
            message: `${message || 'Success'}`,
            variant: 'alert',
            close: true,
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
            },
            alert: {
                variant: 'filled'
            }
        })
    );
};

export function ClearProducts() {
    return async () => {
        try {
            dispatch(slice.actions.clearProducts([]));
        } catch (error) {}
    };
}
