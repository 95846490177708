import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import useAuth from 'hooks/useAuth';

// payloot routing
const CouponsPage = Loadable(lazy(() => import('views/payloot/coupons')));
const BonusesPage = Loadable(lazy(() => import('views/payloot/bonuses')));
const UsersPage = Loadable(lazy(() => import('views/payloot/users')));
const DealsPage = Loadable(lazy(() => import('views/payloot/deals')));
const ClientsPage = Loadable(lazy(() => import('views/payloot/Clients')));
const ItemsPage = Loadable(lazy(() => import('views/payloot/items')));
const LogsPage = Loadable(lazy(() => import('views/payloot/Logs')));
const ReportsPage = Loadable(lazy(() => import('views/payloot/reports')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = () => {
    const { workspace } = useAuth();

    return {
        path: '/',
        element: (
            <AuthGuard>
                <MainLayout />
            </AuthGuard>
        ),
        children: [
            // ------------ PAYLOOT ------------
            {
                path: `/${workspace}`,
                element: <CouponsPage />
            },
            {
                path: `/${workspace}/coupons`,
                element: <CouponsPage />
            },
            {
                path: `/${workspace}/bonuses`,
                element: <BonusesPage />
            },
            {
                path: `/${workspace}/users`,
                element: <UsersPage />
            },
            {
                path: `/${workspace}/deals`,
                element: <DealsPage />
            },
            {
                path: `/${workspace}/clients`,
                element: <ClientsPage />
            },
            {
                path: `/${workspace}/items`,
                element: <ItemsPage />
            },
            {
                path: `/${workspace}/logs`,
                element: <LogsPage />
            },
            {
                path: `/${workspace}/reports`,
                element: <ReportsPage />
            }
        ]
    };
};

export default MainRoutes;
