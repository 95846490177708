// material-ui
import { Divider, Box, Card, Typography, Stack, IconButton, useMediaQuery, Modal, useTheme, Drawer } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

// project imports
import { appDrawerWidth } from 'store/constant';
// import { useDispatch } from 'store';

// types
// import { updateUserInfo } from 'store/slices/security';
// import { UserType } from 'types/user-profile';
import AuthResetPasswordReset from 'views/pages/authentication/auth-forms/AuthResetPasswordReset';
import { useIntl } from 'react-intl';

// import { openSnackbar } from 'store/slices/snackbar';

const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    maxWidth: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    pt: 2,
    px: 3,
    pb: 3,
    borderRadius: 2
};

export default function ChangePassword({ show, handleClose }: { show: boolean; handleClose: () => void }) {
    // const dispatch = useDispatch();
    const theme = useTheme();
    const intl = useIntl();

    const onlyMediumScreen = useMediaQuery(theme.breakpoints.down('xl'));

    const content = () => (
        <>
            <Stack mb={2} direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="h3">{intl.formatMessage({ id: 'change-password' })}</Typography>
                <IconButton onClick={handleClose} sx={{ color: 'divider' }}>
                    <CloseOutlinedIcon />
                </IconButton>
            </Stack>
            <Divider sx={{ mb: 3 }} />

            <Box>
                <AuthResetPasswordReset handleClose={handleClose} />
            </Box>
        </>
    );

    if (onlyMediumScreen) {
        return (
            <Modal
                open={show}
                onClose={handleClose}
                aria-labelledby="modal-edit-category"
                aria-describedby="modal-render-form-edit-category"
            >
                <Box sx={modalStyle}> {content()}</Box>
            </Modal>
        );
    }

    return (
        <Drawer
            open={show}
            sx={{
                ml: show ? 3 : 0,
                height: '100vh',
                flexShrink: 0,
                overflowX: 'hidden',
                width: appDrawerWidth,
                '& .MuiDrawer-paper': {
                    height: 'auto',
                    width: appDrawerWidth,
                    border: 'none'
                }
            }}
            anchor="right"
            ModalProps={{ keepMounted: true }}
            onClose={handleClose}
        >
            <Card
                elevation={2}
                sx={{
                    p: 3,
                    position: 'sticky',
                    bottom: '100%',
                    background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[50],
                    border: '1px solid',
                    borderColor: theme.palette.mode === 'dark' ? 'transparent' : theme.palette.grey[100],
                    '&:hover': {
                        border: `1px solid${theme.palette.primary.main}`
                    }
                }}
            >
                {content()}
            </Card>
        </Drawer>
    );
}
