import { useTheme, Toolbar, AppBar } from '@mui/material';
import { RedocStandalone } from 'redoc';
import './docs.css';
import HeaderDocs from './HeaderDocs';
import { useMemo } from 'react';
import { API_SWAGGER } from 'config';

// ==============================|| PRODUCT LIST ||============================== //

const DocsPage = () => {
    const theme = useTheme();

    const header = useMemo(
        () => (
            <Toolbar>
                <HeaderDocs />
            </Toolbar>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );
    return (
        <>
            <AppBar enableColorOnDark position="sticky" color="inherit" sx={{ bgcolor: theme.palette.background.default }}>
                {header}
            </AppBar>
            <RedocStandalone
                specUrl={API_SWAGGER}
                options={{
                    disableSearch: true,
                    theme: {
                        colors: {
                            primary: {
                                main: theme.palette.primary.main,
                                dark: theme.palette.primary.dark,
                                light: theme.palette.primary.light,
                                contrastText: theme.palette.primary.contrastText
                            },
                            success: {
                                main: theme.palette.success.main,
                                dark: theme.palette.success.dark,
                                light: theme.palette.success.light,
                                contrastText: theme.palette.success.contrastText
                            },
                            error: {
                                main: theme.palette.error.main,
                                dark: theme.palette.error.dark,
                                light: theme.palette.error.light,
                                contrastText: theme.palette.error.contrastText
                            },
                            warning: {
                                main: theme.palette.warning.main,
                                dark: theme.palette.warning.dark,
                                light: theme.palette.warning.light,
                                contrastText: theme.palette.warning.contrastText
                            },
                            text: {
                                primary: theme.palette.text.primary,
                                secondary: theme.palette.text.secondary
                            },
                            border: {
                                light: theme.palette.divider,
                                dark: theme.palette.divider
                            },
                            gray: {
                                50: '#111523',
                                100: '#111523'
                            },
                            responses: {
                                success: { tabTextColor: 'white' }
                            }
                        },
                        typography: {
                            fontSize: `${theme.typography.fontSize}px`,
                            fontFamily: theme.typography.fontFamily,
                            fontWeightLight: theme.typography.fontWeightLight?.toString(),
                            fontWeightRegular: theme.typography.fontWeightRegular?.toString(),
                            fontWeightBold: theme.typography.fontWeightBold?.toString(),
                            code: {
                                fontSize: `${theme.typography.fontSize}px`,
                                fontFamily: 'monospace'
                            },
                            headings: {
                                fontFamily: theme.typography.fontFamily,
                                fontWeight: theme.typography.fontWeightBold?.toString()
                            },
                            links: {
                                color: theme.palette.text.secondary
                            }
                        },
                        sidebar: {
                            backgroundColor: theme.palette.background.paper,
                            textColor: theme.palette.text.secondary,
                            activeTextColor: theme.palette.text.primary
                        },
                        schema: {
                            typeTitleColor: theme.palette.secondary.dark
                        },
                        rightPanel: {
                            backgroundColor: theme.palette.background.paper,
                            servers: {
                                overlay: { backgroundColor: theme.palette.background.paper },
                                url: { backgroundColor: theme.palette.background.paper }
                            }
                        }
                    }
                }}
            />
        </>
    );
};

export default DocsPage;
