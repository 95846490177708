// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "", "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paper": "#ffffff",
	"primaryLight": "#eceff1",
	"primary200": "#b0bec5",
	"primaryMain": "#607d8b",
	"primaryDark": "#546e7a",
	"primary800": "#455a64",
	"secondaryLight": "#e0f2f1",
	"secondary200": "#80cbc4",
	"secondaryMain": "#009688",
	"secondaryDark": "#00897b",
	"secondary800": "#00695c",
	"successLight": "#edf7ed",
	"success200": "#b6e0b3",
	"successMain": "#6cc067",
	"successDark": "#64ba5f",
	"errorLight": "#e48784",
	"errorMain": "#d9534f",
	"errorDark": "#d54c48",
	"orangeLight": "#fbe9e7",
	"orangeMain": "#ffab91",
	"orangeDark": "#d84315",
	"warningLight": "#fdf5ea",
	"warningMain": "#f0ad4e",
	"warningDark": "#ec9c3d",
	"grey50": "#fafafa",
	"grey100": "#f5f5f5",
	"grey200": "#eeeeee",
	"grey300": "#e0e0e0",
	"grey500": "#9e9e9e",
	"grey600": "#757575",
	"grey700": "#616161",
	"grey900": "#212121",
	"darkPaper": "#060d12",
	"darkBackground": "#0e1b23",
	"darkLevel1": "#0b161d",
	"darkLevel2": "#14252f",
	"darkTextTitle": "#e4e8f7",
	"darkTextPrimary": "#d5d9e9",
	"darkTextSecondary": "#d8ddf0",
	"darkPrimaryLight": "#eceff1",
	"darkPrimaryMain": "#78919c",
	"darkPrimaryDark": "#587583",
	"darkPrimary200": "#b0bec5",
	"darkPrimary800": "#44606e",
	"darkSecondaryLight": "#e0f2f1",
	"darkSecondaryMain": "#009688",
	"darkSecondaryDark": "#00897b",
	"darkSecondary200": "#80cbc4",
	"darkSecondary800": "#00695c"
};
export default ___CSS_LOADER_EXPORT___;
