import React, { createContext, useEffect, useReducer } from 'react';

// third-party
import { CognitoUser, CognitoUserPool, CognitoUserSession, CognitoUserAttribute, AuthenticationDetails } from 'amazon-cognito-identity-js';

// reducer - state management
import { LOGIN, LOGOUT, ERROR, WORKSPACE } from 'store/actions';
import accountReducer from 'store/accountReducer';

// project imports
import Loader from 'ui-component/Loader';
import { AWS_API } from 'config';
import { AWSCognitoContextType, InitialLoginContextProps } from 'types/auth';
import { useLocation } from 'react-router-dom';

import { openSnackbar } from 'store/slices/snackbar';
import { useDispatch } from 'store';
import { ClearCoupons } from 'store/slices/payloot/coupons';
import { ClearUsers, userWorkspace } from 'store/slices/payloot/getUsers';
import { ClearClients } from 'store/slices/payloot/clients';
import { ClearDeals } from 'store/slices/payloot/deals';
import { ClearLogs } from 'store/slices/payloot/logs';
import { ClearProducts } from 'store/slices/payloot/products';

// constant
const initialState: InitialLoginContextProps = {
    isLoggedIn: false,
    isInitialized: false,
    user: null,
    passwordIncorrect: false,
    workspace: ''
};

export const userPool = new CognitoUserPool({
    UserPoolId: AWS_API.poolId || '',
    ClientId: AWS_API.appClientId || ''
});

type setSessionProps = {
    serviceToken?: string | null;
    user?: string | null;
};

const setSession = ({ serviceToken, user }: setSessionProps) => {
    if (serviceToken && user) {
        localStorage.setItem('serviceToken', serviceToken);
        localStorage.setItem('user', user);
    } else {
        localStorage.removeItem('serviceToken');
    }
};

// ==============================|| AWS Cognito CONTEXT & PROVIDER ||============================== //
const AWSCognitoContext = createContext<AWSCognitoContextType | null>(null);

export const AWSCognitoProvider = ({ children }: { children: React.ReactElement }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);

    const location = useLocation();
    const dispatchStore = useDispatch();

    const clearState = () => {
        setTimeout(() => {
            dispatchStore(ClearCoupons());
            dispatchStore(ClearUsers());
            dispatchStore(ClearClients());
            dispatchStore(ClearDeals());
            dispatchStore(ClearLogs());
            dispatchStore(ClearProducts());
        }, 1000);
    };

    useEffect(() => {
        const serviceToken = window.localStorage.getItem('serviceToken');

        if (serviceToken) {
            const expirationToken = expToken(serviceToken);
            const dateNow = Date.now();

            if (expirationToken <= dateNow) {
                logout();
                clearState();
            }
        } else {
            logout();
            clearState();
        }
    }, [location.pathname]);

    useEffect(() => {
        const init = async () => {
            try {
                const localWorkspace = localStorage.getItem('workspace');
                const serviceToken = window.localStorage.getItem('serviceToken');
                const userName = window.localStorage.getItem('user') ?? '';
                if (serviceToken) {
                    setSession({ serviceToken, user: userName });
                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: true,
                            user: {
                                user: userName
                            },
                            workspace: localWorkspace
                        }
                    });
                } else {
                    dispatch({
                        type: LOGOUT
                    });
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: LOGOUT
                });
            }
        };

        init();
    }, []);

    const login = async (email: string, password: string) => {
        const usr = new CognitoUser({
            Username: email,
            Pool: userPool
        });

        const authData = new AuthenticationDetails({
            Username: email,
            Password: password
        });

        usr.authenticateUser(authData, {
            onSuccess: async (session: CognitoUserSession) => {
                setSession({ serviceToken: session.getAccessToken().getJwtToken(), user: authData.getUsername() });

                dispatch({
                    type: LOGIN,
                    payload: {
                        isLoggedIn: true,
                        user: {
                            email: authData.getUsername(),
                            user: authData.getUsername()
                        }
                    }
                });
            },
            onFailure: (_err) => {
                dispatch({
                    type: ERROR,
                    payload: {
                        isLoggedIn: false,
                        passwordIncorrect: true
                    }
                });
            },
            newPasswordRequired: (userAttributes, requiredAttributes) => {
                // User was signed up by an admin and must provide new
                // password and required attributes, if any, to complete
                // authentication.
                // the api doesn't accept this field back
                delete userAttributes.email_verified;
                // unsure about this field, but I don't send this back
                delete userAttributes.phone_number_verified;
                // Get these details and call
                delete userAttributes.email;
                usr.completeNewPasswordChallenge(password, userAttributes, requiredAttributes);
            }
        });
    };

    const changePasswordCognito = async (currentPassword: string, newPassword: string) => {
        const user = userPool.getCurrentUser();

        if (user != null) {
            user.getSession(async (err: Error | null, session: CognitoUserSession | null) => {
                if (err) {
                    snackbarShow(err.message, 'error');
                    return;
                }

                if (session) {
                    const authenticationData = {
                        Username: user.getUsername(),
                        Password: currentPassword
                    };
                    const authenticationDetails = new AuthenticationDetails(authenticationData);

                    await new Promise<void>((resolve, reject) => {
                        user.authenticateUser(authenticationDetails, {
                            onSuccess: () => {
                                // Autenticación exitosa con la contraseña actual.
                                // Ahora podemos cambiar la contraseña.
                                user.changePassword(
                                    currentPassword,
                                    newPassword,
                                    (err1: Error | undefined, result: 'SUCCESS' | undefined) => {
                                        if (err1) {
                                            snackbarShow(err1.message, 'error');
                                            reject(err1);
                                        } else if (result === 'SUCCESS') {
                                            snackbarShow('Password successfully updated', 'success');
                                            resolve();
                                        } else {
                                            snackbarShow('Error updating password', 'error');
                                            reject(new Error('No se pudo cambiar la contraseña.'));
                                        }
                                    }
                                );
                            },
                            onFailure: (err1: Error) => {
                                snackbarShow(err1.message, 'error');
                                reject(err1);
                            }
                        });
                    });
                }
            });
        }
    };

    const register = (email: string, password: string, firstName: string, lastName: string, workspace: string | null | undefined) =>
        new Promise((success, rej) => {
            userPool.signUp(
                email,
                password,
                [
                    new CognitoUserAttribute({ Name: 'email', Value: email }),
                    new CognitoUserAttribute({ Name: 'name', Value: `${firstName} ${lastName}` })
                ],
                [],
                async (err, result) => {
                    if (err) {
                        snackbarShow('Registration could not be completed', 'error');
                        rej(err);
                        return;
                    }

                    dispatchStore(userWorkspace(email));
                    success(result);
                    snackbarShow('Registration successfully completed', 'success');
                }
            );
        });

    const logout = () => {
        const loggedInUser = userPool.getCurrentUser();
        if (loggedInUser) {
            setSession({});
            loggedInUser.getSession((err: any, result: any) => {
                if (result) {
                    loggedInUser.globalSignOut({
                        onSuccess: (resultLogout) => {
                            dispatch({
                                type: LOGOUT
                            });
                        },
                        onFailure: (errLogout) => {
                            //err;
                        }
                    });
                }
            });
        }
    };

    const setSelectedWorkspace = (workspace: string) => {
        localStorage.setItem('workspace', workspace);
        dispatch({
            type: WORKSPACE,
            payload: {
                workspace,
                isLoggedIn: true
            }
        });
    };

    const resetPassword = (email: string) => {
        const usr = new CognitoUser({
            Username: email.toLowerCase(),
            Pool: userPool
        });

        usr.forgotPassword({
            onSuccess: (data) => {},
            onFailure: (err) => {},
            inputVerificationCode: (data) => {}
        });
    };

    const changeIncorrect = (change: boolean) => {
        dispatch({
            type: ERROR,
            payload: {
                passwordIncorrect: change,
                isLoggedIn: false
            }
        });
    };

    const snackbarShow = (message: string, color: 'success' | 'error') => {
        dispatchStore(
            openSnackbar({
                open: true,
                message: message,
                variant: 'alert',
                close: true,
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                },
                alert: {
                    variant: 'filled',
                    color: color === 'success' ? '' : 'error'
                }
            })
        );
    };

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <AWSCognitoContext.Provider
            value={{ ...state, login, logout, register, changeIncorrect, resetPassword, changePasswordCognito, setSelectedWorkspace }}
        >
            {children}
        </AWSCognitoContext.Provider>
    );
};

export default AWSCognitoContext;

function expToken(token: string) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split('')
            .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
            .join('')
    );

    const parsed = JSON.parse(jsonPayload);

    return parsed.exp * 1000;
}
