// action - state management
import { UserProfile } from 'types/user-profile';
import { LOGIN, LOGOUT, REGISTER, ERROR, WORKSPACE } from './actions';

// ==============================|| ACCOUNT REDUCER ||============================== //

interface AccountReducerActionProps {
    type: string;
    payload?: InitialLoginContextProps;
}
export interface InitialLoginContextProps {
    isLoggedIn: boolean;
    isInitialized?: boolean;
    user?: UserProfile | null | undefined;
    passwordIncorrect?: boolean;
    workspace?: string | null | undefined;
}

const initialState: InitialLoginContextProps = {
    isLoggedIn: false,
    isInitialized: false,
    user: null,
    passwordIncorrect: false,
    workspace: ''
};

// eslint-disable-next-line
const accountReducer = (state = initialState, action: AccountReducerActionProps) => {
    switch (action.type) {
        case REGISTER: {
            const { user } = action.payload!;
            return {
                ...state,
                user
            };
        }
        case LOGIN: {
            const { user, workspace } = action.payload!;
            return {
                ...state,
                isLoggedIn: true,
                isInitialized: true,
                user,
                workspace
            };
        }
        case LOGOUT: {
            localStorage.removeItem('workspace');
            return {
                ...state,
                isInitialized: true,
                isLoggedIn: false,
                user: null,
                workspace: ''
            };
        }
        case ERROR: {
            const { passwordIncorrect } = action.payload!;
            return {
                ...state,
                passwordIncorrect
            };
        }
        case WORKSPACE: {
            const { workspace } = action.payload!;
            return {
                ...state,
                workspace
            };
        }
        default: {
            return { ...state };
        }
    }
};

export default accountReducer;
