// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../../index';

// types
import { DefaultRootStateProps } from 'types';
import { openSnackbar } from '../snackbar';
import * as R from 'ramda';
import { BonusesType, BonusesTypeCreate, ConfigType } from 'types/e-commerce';

// ----------------------------------------------------------------------

const initialState: DefaultRootStateProps['bonuses'] = {
    error: null,
    loading: false,
    nextToken: null,
    bonuses: [],
    config: {} as ConfigType,
    stores: []
};

const slice = createSlice({
    name: 'bonuses',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },
        setLoading(state, action) {
            state.loading = action.payload;
        },
        getBonusesSuccess(state, action) {
            const search = action?.payload?.config?.params?.search;
            const bonuses = R.uniqBy(
                (i: BonusesType) => i.id,
                (search && action.payload.data) || R.concat(action.payload.data, state.bonuses)
            );
            const nextToken = action?.payload?.headers?.['x-next-token'];

            state.bonuses = bonuses?.sort((a, b) => a.id.localeCompare(b.id));
            state.nextToken = nextToken;
        },
        getConfigBonuses(state, action) {
            state.config = action.payload.data;
        },
        getStoresSuccess(state, action) {
            state.stores = action.payload.data;
        },
        deleteBonusSuccess(state, action) {
            state.bonuses = state.bonuses?.filter((bonus) => bonus.id !== action.payload);
        },
        clearBonuses(state, action) {
            state.bonuses = [];
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getBonuses(search?: string, nextToken?: string) {
    return async () => {
        try {
            dispatch(slice.actions.setLoading(true));

            const response = await axios.get(`/bonuses`, {
                params: {
                    ...(search && { search })
                },
                headers: {
                    ...(nextToken && { 'x-next-token': nextToken }),
                    'Cache-Control': 'no-cache',
                    Connection: 'keep-alive'
                }
            });

            dispatch(slice.actions.getBonusesSuccess(response));
            dispatch(slice.actions.setLoading(false));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            displayError(error);
        }
    };
}

export function deleteBonus(id: string) {
    return async () => {
        try {
            await axios.delete(`/bonuses/${id}`);
            dispatch(slice.actions.deleteBonusSuccess(id));
            displaySuccess('Bonus deleted successfully');
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            displayError(error);
        }
    };
}

export function createBonus(bonus: BonusesTypeCreate) {
    return async () => {
        try {
            await axios.post(`/bonuses`, bonus);
            displaySuccess('Bonus created successfully');
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            await displayError(error);
        }
    };
}

export function editBonus(bonus: BonusesType) {
    return async () => {
        try {
            await axios.patch(`/bonuses/${bonus.code}`, bonus);
            displaySuccess('Bonus edit successfully');
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            await displayError(error);
        }
    };
}

export function getBonusesConfig() {
    return async () => {
        try {
            const response = await axios.get('/bonuses/config');
            dispatch(slice.actions.getConfigBonuses(response));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            await displayError(error);
        }
    };
}

export function getStores() {
    return async () => {
        try {
            const response = await axios.get('/stores');
            dispatch(slice.actions.getStoresSuccess(response));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            await displayError(error);
        }
    };
}

export function updateConfig(config: ConfigType) {
    return async () => {
        try {
            await axios.post('/bonuses/config', config);
            dispatch(
                openSnackbar({
                    open: true,
                    message: `${'Config successfully updated'}`,
                    variant: 'alert',
                    close: true,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left'
                    },
                    alert: {
                        variant: 'filled'
                    }
                })
            );
            dispatch(getBonusesConfig());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            await displayError(error);
        }
    };
}

const displayError = async (error: any) => {
    dispatch(
        openSnackbar({
            open: true,
            message: `${
                error?.message ||
                error?.response?.data?.issues ||
                error?.response?.data?.message ||
                (error?.response?.data && JSON.stringify(error?.response?.data)) ||
                error
            }`,
            variant: 'alert',
            close: true,
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
            },
            alert: {
                color: 'error',
                variant: 'filled'
            }
        })
    );
};

const displaySuccess = async (message?: string) => {
    dispatch(getBonuses());
    dispatch(slice.actions.setLoading(false));
    dispatch(
        openSnackbar({
            open: true,
            message: `${message || 'Success'}`,
            variant: 'alert',
            close: true,
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
            },
            alert: {
                variant: 'filled'
            }
        })
    );
};

export function ClearBonuses() {
    return async () => {
        try {
            dispatch(slice.actions.clearBonuses([]));
        } catch (error) {}
    };
}
