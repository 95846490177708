import LAYOUT_CONST from 'constant';

// types
import { ConfigProps } from 'types/config';

export const JWT_API = {
    secret: '',
    timeout: ''
};

export const FIREBASE_API = {
    apiKey: '',
    authDomain: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: ''
};

export const AUTH0_API = {
    client_id: '',
    domain: ''
};

export const AWS_API = {
    poolId: process.env.REACT_APP_USER_POOL_ID,
    appClientId: process.env.REACT_APP_CLIENT_ID
};

export const API_URL = process.env.REACT_APP_API_BASE_URL;
export const API_SWAGGER = `${process.env.REACT_APP_API_BASE_URL}/api/swagger.json`;

// basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
// like '/berry-material-react/react/default'
export const BASE_PATH = '';

const workspace = localStorage.getItem('workspace');

export const DASHBOARD_PATH = `/${workspace}/coupons`;

export const HORIZONTAL_MAX_ITEM = 6;

const config: ConfigProps = {
    layout: LAYOUT_CONST.VERTICAL_LAYOUT, // vertical, horizontal
    drawerType: LAYOUT_CONST.MINI_DRAWER, // default, mini-drawer
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 8,
    outlinedFilled: true,
    navType: 'dark', // light, dark
    presetColor: 'default', // default, theme1, theme2, theme3, theme4, theme5, theme6
    locale: 'en', // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
    rtlLayout: false,
    container: true
};

export default config;
