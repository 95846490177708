// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../../index';

// types
import { DefaultRootStateProps } from 'types';
import { openSnackbar } from '../snackbar';
import * as R from 'ramda';
import { ClientType } from 'types/payloot/clients';

// ----------------------------------------------------------------------

const initialState: DefaultRootStateProps['clients'] = {
    error: null,
    loading: false,
    loadingDeals: false,
    nextToken: null,
    clients: [],
    clientDeals: [],
    clientCount: { value: 0, count: 0, active: false, name: '', id: '' }
};

const slice = createSlice({
    name: 'client',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },
        setLoading(state, action) {
            state.loading = action.payload;
        },
        setLoadingDeals(state, action) {
            state.loadingDeals = action.payload;
        },
        getClientsSuccess(state, action) {
            console.log(action.payload);

            const search = action?.payload?.config?.params?.search;
            const clients = R.uniqBy(
                (i: ClientType) => i.id,
                (search && action.payload.data) || R.concat(action.payload.data, state.clients)
            );
            const nextToken = action?.payload?.headers?.['x-next-token'];
            console.log(clients, 'clients');

            state.clients = clients?.sort((a, b) => a.id.localeCompare(b.id));
            state.nextToken = nextToken;
        },
        getClientDealsSuccess(state, action) {
            const nextToken = action?.payload?.headers?.['x-next-token'];

            state.clientDeals = action.payload.data;
            state.nextToken = nextToken;
        },
        deleteClientSuccess(state, action) {
            state.clients = state.clients?.filter((client) => client.id !== action.payload);
        },
        getClientCountSuccess(state, action) {
            state.clientCount = action.payload.data;
        },
        clearClients(state, action) {
            state.clients = [];
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getClients(search?: string, nextToken?: string) {
    return async () => {
        try {
            dispatch(slice.actions.setLoading(true));

            const response = await axios.get('/clients', {
                params: {
                    ...(search && { search })
                },
                headers: {
                    ...(nextToken && { 'x-next-token': nextToken }),
                    'Cache-Control': 'no-cache',
                    Connection: 'keep-alive'
                }
            });

            dispatch(slice.actions.getClientsSuccess(response));
            dispatch(slice.actions.setLoading(false));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            displayError(error);
        }
    };
}

export function updateClient(client: ClientType) {
    return async () => {
        try {
            await axios.patch(`/clients/${client.id}`, client);
            displaySuccess('Client updated successfully');
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            displayError(error);
        }
    };
}

export function deleteClient(client: ClientType) {
    return async () => {
        try {
            await axios.delete(`/clients/${client.id}`);
            dispatch(slice.actions.deleteClientSuccess(client.id));
            displaySuccess('Client deleted successfully');
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            displayError(error);
        }
    };
}

export function createClient(client: ClientType) {
    return async () => {
        try {
            await axios.post(`/clients`, client);
            displaySuccess('Client created successfully');
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            await displayError(error);
        }
    };
}

export function getClientsDeals(id?: string, nextToken?: string) {
    return async () => {
        try {
            dispatch(slice.actions.setLoadingDeals(true));
            const response = await axios.get(`/clients/${id}/deals`, {
                headers: {
                    ...(nextToken && { 'x-next-token': nextToken })
                }
            });
            dispatch(slice.actions.getClientDealsSuccess(response));
            dispatch(slice.actions.setLoadingDeals(false));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            displayError(error);
        }
    };
}

export function getClientCounts(id?: string) {
    return async () => {
        try {
            dispatch(slice.actions.setLoadingDeals(true));
            const response = await axios.get(`/orders/${id}/count`);
            dispatch(slice.actions.getClientCountSuccess(response));
            dispatch(slice.actions.setLoadingDeals(false));
        } catch (error: any) {
            dispatch(slice.actions.hasError(error));
            dispatch(slice.actions.setLoadingDeals(false));
            if (error.response.status === 404) {
                dispatch(
                    slice.actions.getClientCountSuccess({
                        value: 0,
                        count: 0,
                        name: '',
                        id: ''
                    })
                );
            } else {
                await displayError(error);
            }
        }
    };
}

const displayError = async (error: any) => {
    dispatch(
        openSnackbar({
            open: true,
            message: `${
                error?.message ||
                error?.response?.data?.issues ||
                error?.response?.data?.message ||
                (error?.response?.data && JSON.stringify(error?.response?.data)) ||
                error
            }`,
            variant: 'alert',
            close: true,
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
            },
            alert: {
                color: 'error',
                variant: 'filled'
            }
        })
    );
};

const displaySuccess = async (message?: string) => {
    dispatch(getClients());
    dispatch(slice.actions.setLoading(false));
    dispatch(
        openSnackbar({
            open: true,
            message: `${message || 'Success'}`,
            variant: 'alert',
            close: true,
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
            },
            alert: {
                variant: 'filled'
            }
        })
    );
};

export function ClearClients() {
    return async () => {
        try {
            dispatch(slice.actions.clearClients([]));
        } catch (error) {}
    };
}
