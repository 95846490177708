// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "", "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paper": "#ffffff",
	"primaryLight": "#e8eaf6",
	"primary200": "#9fa8da",
	"primaryMain": "#3f51b5",
	"primaryDark": "#3949ab",
	"primary800": "#283593",
	"secondaryLight": "#e8eaf6",
	"secondary200": "#9fa8da",
	"secondaryMain": "#3f51b5",
	"secondaryDark": "#3949ab",
	"secondary800": "#283593",
	"successLight": "#b9f6ca",
	"success200": "#69f0ae",
	"successMain": "#00e676",
	"successDark": "#00c853",
	"errorLight": "#ef9a9a",
	"errorMain": "#f44336",
	"errorDark": "#c62828",
	"orangeLight": "#fbe9e7",
	"orangeMain": "#ffab91",
	"orangeDark": "#d84315",
	"warningLight": "#fff8e1",
	"warningMain": "#ffe57f",
	"warningDark": "#ffc107",
	"grey50": "#fafafa",
	"grey100": "#f5f5f5",
	"grey200": "#eeeeee",
	"grey300": "#e0e0e0",
	"grey500": "#9e9e9e",
	"grey600": "#757575",
	"grey700": "#616161",
	"grey900": "#212121",
	"darkPaper": "#111936",
	"darkBackground": "#1a223f",
	"darkLevel1": "#29314f",
	"darkLevel2": "#212946",
	"darkTextTitle": "#d7dcec",
	"darkTextPrimary": "#bdc8f0",
	"darkTextSecondary": "#8492c4",
	"darkPrimaryLight": "#eeedfd",
	"darkPrimaryMain": "#7267ef",
	"darkPrimaryDark": "#6a5fed",
	"darkPrimary200": "#b9b3f7",
	"darkPrimary800": "#554ae8",
	"darkSecondaryLight": "#eeedfd",
	"darkSecondaryMain": "#7267ef",
	"darkSecondaryDark": "#6a5fed",
	"darkSecondary200": "#b9b3f7",
	"darkSecondary800": "#554ae8"
};
export default ___CSS_LOADER_EXPORT___;
