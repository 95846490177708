// import { lazy } from 'react';
import { useRoutes, Navigate } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import LoginRoutes from './LoginRoutes';
import DocsPage from 'views/payloot/docs';
import ReleasesView from 'views/payloot/Releases';
import AuthForgotPassword from 'views/pages/authentication/authentication3/ForgotPassword3';
// import AuthenticationRoutes from './AuthenticationRoutes';
// import Loadable from 'ui-component/Loadable';

// const PagesLanding = Loadable(lazy(() => import('views/pages/landing')));

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([
        {
            path: '/docs',
            element: <DocsPage />
        },
        {
            path: '/releases',
            element: <ReleasesView />
        },
        // AuthenticationRoutes,
        {
            path: '/forgot',
            element: <AuthForgotPassword />
        },
        LoginRoutes,
        MainRoutes(),
        {
            path: '/*',
            element: <Navigate to="/" replace />
        }
    ]);
}
